var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "novel-title-wrapper" }, [
        _c("img", {
          staticClass: "book-icon",
          attrs: { src: require("@/assets/img/icon/book.svg"), alt: "book" },
        }),
        _c("div", { staticClass: "novel-title ellipsis" }, [
          _vm._v(_vm._s(_vm.novelTitle)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "list" },
      [
        _vm._m(0),
        _c(
          "transition-group",
          { attrs: { name: "tran" } },
          _vm._l(_vm.manuscriptList, function (item) {
            return _c(
              "div",
              {
                key: item.key,
                staticClass: "item",
                class: { active: _vm.contentKey === item.key },
                on: {
                  click: function ($event) {
                    item.key &&
                      _vm.contentKey !== item.key &&
                      _vm.select(item.key, _vm.SharedLinkKind.MANUSCRIPT)
                  },
                },
              },
              [
                _c("div", { staticClass: "item-title ellipsis" }, [
                  _vm._v(" " + _vm._s(item.title || "(タイトル未設定)") + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list" },
      [
        _vm._m(1),
        _c(
          "transition-group",
          { attrs: { name: "tran" } },
          _vm._l(_vm.plotList, function (item) {
            return _c(
              "div",
              {
                key: item.plotId,
                staticClass: "item",
                class: { active: _vm.contentKey === item.plotId },
                on: {
                  click: function ($event) {
                    item.plotId &&
                      _vm.contentKey !== item.plotId &&
                      _vm.select(item.plotId, _vm.SharedLinkKind.PLOT)
                  },
                },
              },
              [
                _c("div", { staticClass: "item-title ellipsis" }, [
                  _vm._v(" " + _vm._s(item.name || "(タイトル未設定)") + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header-title" }, [
      _c("span", [_vm._v("共有されている原稿一覧")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header-title" }, [
      _c("span", [_vm._v("共有されているプロット一覧")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }