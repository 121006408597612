var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.kind === _vm.SharedLinkKind.MANUSCRIPT
      ? _c("div", { staticClass: "shared-link-content" }, [
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.initializeQuote,
                    expression: "initializeQuote",
                  },
                ],
                staticClass: "textarea",
                on: { mousedown: _vm.initializeQuote },
              },
              [_vm._v(_vm._s(_vm.content))]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.kind === _vm.SharedLinkKind.PLOT
      ? _c(
          "div",
          { staticClass: "shared-link-content textarea" },
          [
            _vm.plot
              ? _c("plots", {
                  attrs: {
                    novelId: _vm.novelId,
                    plotId: _vm.contentKey,
                    plot: _vm.plot,
                    isViewedByExternalUser: true,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    !_vm.contentKey
      ? _c("div", { staticClass: "description-mask" }, [
          _c("h2", [
            _vm._v("共有されている資料一覧から資料を選択してください"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }