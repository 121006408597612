var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "shared-content-page" },
    [
      _c("shared-link-list", {
        staticClass: "shared-link-list",
        attrs: {
          sharedLinkId: _vm.sharedLinkId,
          novelId: _vm.novelId,
          contentKey: _vm.contentKey,
        },
        on: { contentSelect: _vm.onContentSelect },
      }),
      _c("shared-link-content", {
        staticClass: "shared-link-content",
        attrs: {
          sharedLinkId: _vm.sharedLinkId,
          novelId: _vm.novelId,
          contentKey: _vm.contentKey,
          kind: _vm.kind,
        },
      }),
      _c("shared-link-comment", {
        staticClass: "shared-link-comment",
        attrs: {
          sharedLinkId: _vm.sharedLinkId,
          novelId: _vm.novelId,
          contentKey: _vm.contentKey,
          kind: _vm.kind,
        },
      }),
      !_vm.isAgreed
        ? _c("div", { staticClass: "mask" }, [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title" }, [
                _vm._v("作品共有機能ご利用前のご確認"),
              ]),
              _c("div", { staticClass: "description" }, [
                _c("span", [
                  _vm._v(
                    " 作品共有機能は、Nolaで執筆した作品の共有リンクを発行し、Nola会員の第三者に共有して感想をもらったり、添削や校閲などを依頼できる機能です。リンクを共有された側も筆者や作品に対して敬意を持った言動をお願いいたします。 "
                  ),
                ]),
                _c("span", { staticClass: "danger" }, [
                  _vm._v(
                    " また、複数人にリンクを共有されている場合、コメント内容は他の人も閲覧ができますのでご注意ください。 "
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("なお、Nolaでは快適なご利用のため「")]),
                    _c(
                      "router-link",
                      {
                        staticClass: "policy",
                        attrs: { to: { name: "shared" }, target: "_blank" },
                      },
                      [_vm._v("共有ポリシー")]
                    ),
                    _c("span", [
                      _vm._v(
                        " 」を定めておりますので、こちらのページの内容をよく読んだ上でご利用のほどよろしくお願いいたします。 "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "agreement",
                  on: { click: _vm.onClickCheckbox },
                },
                [
                  _c("checkbox-icon", {
                    staticClass: "checkbox",
                    attrs: { isChecked: _vm.isChecked },
                  }),
                  _c(
                    "router-link",
                    {
                      staticClass: "policy",
                      attrs: { to: { name: "shared" }, target: "_blank" },
                    },
                    [_vm._v("共有ポリシー")]
                  ),
                  _c("div", [
                    _vm._v("をよく読み、内容に同意の上利用を開始する"),
                  ]),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { disabled: !_vm.isChecked },
                  on: { click: _vm.onClickStart },
                },
                [_vm._v("利用を開始する")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }